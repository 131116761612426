import React, { useEffect, useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { getPledge } from "../axios/axios";

const useStyles = makeStyles((theme) => ({
  text: {
    padding: "10px",
    textAlign: "center",
    [theme.breakpoints.down(500)]: {
      textAlign: "center",
    },
  },
  button: {
    marginLeft: "20px",
    height: "60px",
    fontWeight: "600",
    padding: "10px 40px",
    backgroundColor: "#fff",
    color: "#105A98",
    border: "2px solid #105A98",
    fontSize: "16px",
    boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    [theme.breakpoints.down(500)]: {
      width: "70%",
      display: "block",
      margin: "auto auto",
    },
    "&:hover": {
      backgroundColor: "#105A98",
      color: "#fff",
      border: "2px solid #105A98",
    },
  },
}));

const Card4 = () => {
  const classes = useStyles();

  const [pledgedPeople, setPledgedPeople] = useState(null);

  useEffect(() => {
    const func = setInterval(async () => {
      try {
        const data = await getPledge();
        setPledgedPeople(data.data.data);
      } catch (error) {
        console.log("Failed to fetch pledged info.");
      }
    }, 5000);

    return () => clearInterval(func);
  }, []);

  return (
    <>
      <h1
        style={{
          color: "",
          fontSize: "32px",
          textAlign: "center",
          marginTop: "70px",
        }}
      >
        Cancer Pledge
      </h1>
      <div
        style={{
          width: "100px",
          height: "2px",
          backgroundColor: "#112449",
          margin: "0 auto 50px auto",
        }}
      >
        {" "}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          flexWrap: "wrap-reverse",
          alignContent: "center",
        }}
      >
        <div style={{ maxWidth: "500px" }}>
          <div className={classes.text}>
            <section className={classes.modalOverlay}>
              <br />
              <br />
              {pledgedPeople && (
                <h2 style={{ color: "#112449" }}>
                  {pledgedPeople} People has taken the Cancer pledge till now
                </h2>
              )}
              <br />
              <br />
              <br />
              <h2 style={{ fontSize: "24px", color: "#112449" }}>
                Do you want to take the Cancer pledge ?
              </h2>{" "}
              <br />
              <br />
            </section>
            <NavLink to="/pledge" style={{ textDecoration: "none" }} exact>
              {" "}
              <Button className={classes.button}> Cancer Pledge</Button>
            </NavLink>
          </div>
        </div>
        <div>
          <img
            src={require("../Assets/Rectangle 61.png").default}
            alt=""
            style={{ width: "100%" }}
          />
        </div>
      </div>

      <div
        style={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <NavLink to="/pledge">
          <img src={require("../Assets/Group 1.png").default} alt="" />
        </NavLink>
      </div>
      <img
        src={require("../Assets/Vector 12.png").default}
        alt=""
        style={{ width: "100%", display: "block", margin: "-30px auto" }}
      />
    </>
  );
};

export default Card4;
