import React from 'react'
import { Card, makeStyles, CardContent } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
   card: {
      margin: '30px 0 30px 0',
      width: '500px',
      height: 'auto',
      background: '#FFFFFF',
      boxShadow: ' 0px 10px 35px rgba(0, 0, 0, 0.08)',
      borderRadius: '10px',
      [theme.breakpoints.down(500)]: {
         width: "100%",
         height: 'auto',
         margin: '0 0 0 0px'
      },
   },
}));

const Main = () => {
   const classes = useStyles()
   return (
      <>
         <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
            <div>
               <h1 style={{ color: '#112449', fontSize: '32px', textAlign: 'center', marginTop: '50px' }}>Most Common Cancer In Men</h1>
               <Card className={classes.card}>
                  <CardContent style={{ margin: '-15px 0 0 0px' }}>
                     <img src={require('../Assets/Rectangle 356 (1).png').default} alt="" style={{ width: '100%' }} />
                     <div style={{ margin: '0 0 0 50px' }}>
                        <img src={require('../Assets/Rectangle 24.png').default} alt="" />
                        <span style={{ fontSize: '18px', color: '#105A98', margin: '0 0 0 15px' }}>Prostate Cancer</span><br />
                        <img src={require('../Assets/Rectangle 24.png').default} alt="" />
                        <span style={{ fontSize: '18px', color: '#105A98', margin: '0 0 0 15px' }}>Oral Cancer</span><br />
                        <img src={require('../Assets/Rectangle 24.png').default} alt="" />
                        <span style={{ fontSize: '18px', color: '#105A98', margin: '0 0 0 15px' }}>Stomach Cancer</span><br />
                     </div>
                  </CardContent >
               </Card> <br />
            </div>
            <div>
               <h1 style={{ color: '#112449', fontSize: '32px', textAlign: 'center', marginTop: '50px' }}>Most Common Cancer In Women</h1>
               <Card className={classes.card}>
                  <CardContent style={{ margin: '-15px 0 0 0' }}>
                     <img src={require('../Assets/Rectangle 358 (1).png').default} alt="" style={{ width: '100%' }} />
                     <div style={{ margin: '0 0 0 50px' }}>
                        <img src={require('../Assets/Rectangle 24.png').default} alt="" />
                        <span style={{ fontSize: '18px', color: '#105A98', margin: '0 0 0 15px' }}>Breast Cancer</span><br />
                        <img src={require('../Assets/Rectangle 24.png').default} alt="" />
                        <span style={{ fontSize: '18px', color: '#105A98', margin: '0 0 0 15px' }}>Uterus Cancer</span><br />
                        <img src={require('../Assets/Rectangle 24.png').default} alt="" />
                        <span style={{ fontSize: '18px', color: '#105A98', margin: '0 0 0 15px' }}>Thyroid Cancer</span><br />
                     </div>
                  </CardContent >
               </Card>
            </div>
         </div>

      </>
   )
}

export default Main

