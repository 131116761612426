import React from "react";
import { BrowserRouter as Router, Switch, Route, } from "react-router-dom";
import Pledge from "./Page2/Pledge";
import Contact from "./Components/Contact";
import Home from "./Components/Home";
import HomePage from "./Components/HomePage"
import BloodDonation from "./Components/BloodDonation";
// import Footer from "./Page2/Footer";

const App = () => {
  return (
    <>
      <Router>  
        <Switch> 
        <Route exact path="/">
            <HomePage/>
          </Route>
          <Route exact path="/home">
            <Home />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route exact path="/pledge">
            <Pledge />
          </Route> 
          <Route exact path="/donate">
            <BloodDonation />
          </Route>
          {/* <Route exact path="/footer">
            <Footer />
          </Route> */}
        </Switch>
      </Router>
    </>
  );
};

export default App;
