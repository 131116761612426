import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { 
  FormControlLabel,
  Button,
  makeStyles,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { sendPledge } from "../axios/axios";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  form: {
    [theme.breakpoints.down(500)]: {},
  },
  input: {
    width: "70vw",
    height: "50px",
    border: "none",
    background: "#FFFFFF",
    boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.05)",
    color:'rgba(16, 90, 152, 0.5)',
    borderRadius: "10px",
    padding: "20px",
    [theme.breakpoints.down(500)]: {
      Width: "100%",
    },
  },
  button2: {
    marginTop: "50px",
    fontWeight: "600",
    height: "55px",
    padding: "10px 40px",
    backgroundColor: "#fff",
    color: "#105A98",
    fontSize: "16px",
    border: "2px solid #105A98",
    boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    [theme.breakpoints.down(500)]: {},
    "&:hover": {
      backgroundColor: "#105A98",
      color: "#fff",
      border: "2px solid #105A98",
    },
  },
  button: {
    marginLeft: "50px",
    fontWeight: "600",
    height: "55px",
    padding: "10px 40px",
    backgroundColor: "#fff",
    color: "#105A98",
    fontSize: "16px",
    border: "2px solid #105A98",
    boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    [theme.breakpoints.down(500)]: {
      width: "60%",
      margin: "0 -100px 30px 180px",
    },
    "&:hover": {
      backgroundColor: "#105A98",
      color: "#fff",
      border: "2px solid #105A98",
    },
    icons: {
      position: "absolute",
    },
  },
  texth2: {
    fontSize: "32px",
    color: "#112449",
    fontFamily: "Aclonica",
    display: "block",
    margin: "auto",
    [theme.breakpoints.down(500)]: {
      fontSize: "28px",
      marginLeft: "-60px",
    },
  },
}));

const Footer = () => {
  // taking data from input feild using use state
  const [name, setName] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");

  const [checkboxState, _setCheckboxState] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    // 5: false,
  });
  const setCheckbox = (updates) => {
    _setCheckboxState((state) => ({
      ...state,
      ...updates,
    }));
  };

  const [count, setCount] = useState(null);

  const history = useHistory();

  const submitPledge = async (e) => {
    e.preventDefault();
    try {
      const res = await sendPledge({ name, state, city });
      setCount(res.data.count);
      handleClickOpen();
    } catch (error) {
      console.log("Could not submit form.");
    }
  };

  // Dialog
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCount(null);

    setName("");
    setState("");
    setCity("");
    _setCheckboxState({
      1: false,
      2: false,
      3: false,
      4: false,
      // 5: false,
    });
  };

  return (
    <>
      <h1
        style={{
          color: "#112449",
          fontSize: "32px",
          textAlign: "center",
          marginTop: "70px",
        }}
      >
        Take The Cancer Pledge Now
      </h1>

      <form>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "100px",
            padding: "10px",
          }}
        >
          <div>
            <div style={{ display: "flex" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="checkedB"
                    style={{ color: "#112449",}}
                    onChange={(event) =>
                      setCheckbox({ 1: event.target.checked })
                    }
                    checked={checkboxState[1]}
                  />
                }
              />
              <div>
                <h3
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    color: "#112449",
                  }}
                >
                  Did You Know Cancer Is Not Only A Physical Disease But Affects
                  The Mind On The Patient And Their Caregivers ?
                </h3>
                <h4 style={{ fontSize: "16px", color: "#105A98" }}>
                  ( क्या आप जानते हैं कि कैंसर केवल एक शारीरिक बीमारी नहीं है
                  बल्कि रोगी और उनके देखभाल करने वालों पर दिमाग को प्रभावित करता
                  है ? )
                </h4>
              </div>
            </div>
            <br />

            <div style={{ display: "flex" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event) =>
                      setCheckbox({ 2: event.target.checked })
                    }
                    name="checkedB"
                    checked={checkboxState[2]}
                    style={{color: "#112449", }}
                  />
                }
              />
              <div>
                <h3
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    color: "#112449",
                  }}
                >
                  I Take A Pledge To Keep Myself Healthy And Wealthy And To Make
                  Others Aware Of This Deadly Disease.
                </h3>
                <h4 style={{ fontSize: "16px", color: "#105A98" }}>
                  (मैं प्रतिज्ञा करता/करती हूं, मैं खुद को स्वस्थ और समृद्ध रखने और इस घातक बीमारी के प्रति
                  दूसरों को जागरूक करने का संकल्प लेता/लेती हूं । )
                </h4>
              </div>
            </div>
            <br />

            <div style={{ display: "flex" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event) =>
                      setCheckbox({ 3: event.target.checked })
                    }
                    name="checkedB"
                    checked={checkboxState[3]}
                    style={{ color: "#112449", }}
                  />
                }
              />
              <div>
                <h3
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    color: "#112449",
                  }}
                >
                  I Take A Pledge Not To Consume Any Harmful Products Like
                  Tobacco, Cigarettes, Alcohol & Other.
                </h3>
                <h4 style={{ fontSize: "16px", color: "#105A98" }}>
                  (मैं प्रतिज्ञा करता/करती हूं, मैं किसी भी हानिकारक उत्पाद जैसे सिगरेट, शराब और
                  अन्य का सेवन नहीं करने का संकल्प लेता/लेती हूं। )
                </h4>
              </div>
            </div>
            <br />

            <div style={{ display: "flex" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event) =>
                      setCheckbox({ 4: event.target.checked })
                    }
                    name="checkedB"
                    checked={checkboxState[4]}
                    style={{ color: "#112449", }}
                  />
                }
              />
              <div>
                <h3
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    color: "#112449",
                  }}
                >
                 I Take A Pledge To Aware Other People Too About This Harmful Disease And Support This Campaign. 
                </h3>
                <h4 style={{ fontSize: "16px", color: "#105A98" }}>
                ( मैं प्रतिज्ञा करता/करती हूं, कि मैं दूसरों को भी कैंसर जैसी खतरनाक बीमारी के बारे में जागरूक करूंगा/करूंगी और यह अभियान से जुड़ने के लिए प्रेरित करूंगा/करूंगी।)
                </h4>
              </div>
            </div>
            <br />  
            <br />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
            marginBottom:'100PX'
          }}
        >
          <div>
            <div>
              <h1 style={{ color: "#112449", fontSize: "20px" }}>Name</h1>
              <br />
              <input
                required
                className={classes.input}
                placeholder="Enter Your Name"
                type="text"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </div>
            <br />
            <h1 style={{ color: "#112449", fontSize: "20px" }}>State</h1>
            <br />
            <input
              required
              className={classes.input}
              placeholder="Enter Your State"
              type="text"
              value={state}
              onChange={(event) => setState(event.target.value)}
            />
            <br />
            <br />
            <h1 style={{ color: "#112449", fontSize: "20px" }}>City</h1>
            <br />
            <input
              required
              className={classes.input}
              placeholder="Enter Your City"
              type="text"
              value={city}
              onChange={(event) => setCity(event.target.value)}
            />
            <br />
            <br /> <br /> <br />
            <div
              style={{
                position: "absolute",
                right: "200px",
                marginBottom: "50px",
              }}
            >
              {/* <Button className={classes.button}>Clear</Button> */}
              <Button
                disabled={
                  !checkboxState[1] ||
                  !checkboxState[2] ||
                  !checkboxState[3] ||
                  !checkboxState[4] ||
                  // !checkboxState[5] ||
                  name.length === 0 ||
                  state.length === 0 ||
                  city.length === 0
                }
                type="submit"
                className={classes.button}
                onClick={submitPledge}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </form>

      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
        <DialogContent>
          <div style={{ textAlign: "center", margin: "10px 50px" }}>
            <h2 className={classes.texth2}>CONGRATULATIONS..!</h2>
            <img
              src={require("../Assets/Frame.png").default}
              alt=""
              style={{ width: "100%" }}
            />
            <p style={{ color: "#112449",fontWeight:'600' }}> Anant Aman Society Is Thankful For Your Contribution.</p> 
            <h2 style={{ fontSize: "24px", color: "#112449" }}>
              You Are The {count} Person To Support The Cancer Awareness Campaign.  
            </h2>
            <Button autoFocus onClick={handleClose} className={classes.button2}>
              Continue
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Footer;
