import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import { useFormik } from 'formik';

const useStyles = makeStyles((theme) => ({
  cnclBtn: {
    position: 'absolute', right: '50px', backgroundColor: '#105A98', padding: '15px 18px', borderRadius: '50%',
    [theme.breakpoints.down(500)]: {
      margin: '5px -30px 0 0'
    },
  },
  form: {  marginTop:'5px',
    [theme.breakpoints.down(500)]: {
      width:'80%',
      margin: '-20px 0  50px 0'
    },

  },
  root: {
    background: 'rgba(17, 36, 73, 0.05)',borderRadius: '10px',border: 'none', width: '100%', height: '50px',padding:'20px',color:'rgba(16, 90, 152, 0.5)',
    [theme.breakpoints.down(500)]: {
      Width: '100%',
    },
  },
  button: {
    height: '55px',
    margin: '50px 0 0 0px ',
    fontWeight: '600',
    padding: '10px 40px',
    backgroundColor: "#fff",
    color: "#105A98",
    fontSize: '16px',
    border: "2px solid #105A98",
    boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    [theme.breakpoints.down(500)]: {
      width: "100%",
      margin: '50px 0 10px 0'
    },
    '&:hover': {
      backgroundColor: "#105A98",
      color: "#fff",
      border: '2px solid #105A98'
    },

    icons: {
      position: "absolute",
    },
    card: {
      border: 'none',
      boxShadow: 'none',
      width: '100%',
      height: 'auto',

    },
    CardContent: {
      display: 'flex',
      alignItems: 'center'
    },
  },
}));


const Contact = () => {
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    // onSubmit: values => {
    //   alert(JSON.stringify(values, null, 2));
    // },
  });
  const classes = useStyles()
  return (
    <>
      <div className={classes.cnclBtn} style={{}}>
        <NavLink to="/home" style={{ textDecoration: 'none' }} exact><img src={require('../Assets/Vector 13.png').default} alt="" /></NavLink>

      </div >
      <h1 style={{ color: '#112449', fontSize: '32px', textAlign: 'center', marginTop: '10px' }}>Contact Us</h1>
      <div style={{ width: '100px', height: '2px', backgroundColor: '#112449', margin: '0 auto 50px auto' }}> </div>
      <p style={{ fontSize: '20px', color: '#105A98', textAlign: 'center' }}>Fill Up The Form And Our Team Will Get Back To You Within 24 Hours.</p>
      <br /><br />
      <div style={{ display: 'flex',justifyContent:'space-evenly', flexWrap: 'wrap-reverse',alignItems:'center',marginBottom:'50px'}}>
        <div>
          <img src={require('../Assets/Asset 1 2.png').default} alt="" style={{ width: '100%' }} /><br /><br /><br /><br />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={require('../Assets/Group 32.png').default} alt="" />
           <a href= "mailto:ngo@anantamansws.org" style={{textDecoration:'none'}}><h1 style={{ fontSize: '16px', color: '#112449', margin: '0 0 0 30px' }} >ngo@anantamansws.org</h1></a> 
          </div><br /><br />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={require('../Assets/Group 33.png').default} alt="" />
            <a  href="tel:5558920234" style={{textDecoration:'none'}}> <h1 style={{ fontSize: '16px', color: '#112449', margin: '0 0 0 30px' }}>+91 777-199-7475</h1></a>
          </div><br /><br />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={require('../Assets/Group 34.png').default} alt="" />
            <h1 style={{ fontSize: '16px', color: '#112449', margin: '0 0 0 30px' }}>09, Naresh Appartment,<br/> SadhuVaswani Nagar, Indore (M.P.)</h1>
          </div>
        </div> 
          <div className={classes.form}> 
            <form onSubmit={formik.handleSubmit}>  
            
              <h2 style={{ fontSize: '20px', color: '#112449' }}>Name</h2><br />
              <input className={classes.root}  placeholder="Enter Your Name" type="text" /> 
              <br /> <br />

              <h2 style={{ fontSize: '20px', color: '#112449' }}>Contact</h2><br />
              <input className={classes.root}  placeholder="Enter Your Contact No" type="text"/> 
              <br /> <br />
 
              <h2 style={{ fontSize: '20px', color: '#112449' }}>Email</h2><br />
              <input className={classes.root}  id="email"
                name="email"
                type="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                placeholder="Enter Your Email" /> 
             <br /><br />

             
              <h2 style={{ fontSize: '20px', color: '#112449' }}>Message</h2><br />
              <textarea className={classes.root} placeholder="Message" type="text" style={{height:'100px'}}/> 
            
            <Button className={classes.button} type="submit" href="mailto:ngo@anantamansws.org">Send Message</Button>
            </form>
          </div> 
      </div>
    </>
  )
}

export default Contact

