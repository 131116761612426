import React from 'react'
import { Card,  makeStyles, CardContent } from '@material-ui/core'

const useStyles = makeStyles({
  card: {  
    margin: '30px 0 30px 0',
    width: '336.37px',
    height: '200px',
    background: '#FFFFFF',
    boxShadow: ' 0px 10px 35px rgba(0, 0, 0, 0.08)',
    borderRadius: '10px',
  }
})

const Card1 = (props) => {
  const classes = useStyles()
  return (
    <>

      <Card className={classes.card}>
        <CardContent style={{ margin: '0 0 0 30px' }}>
          <h2 style={{ fontSize: '20px', color: '#112449' }}>{props.title}</h2><br/>
          <img src={require('../Assets/Rectangle 24.png').default} alt="" /><span style={{ fontSize: '18px', color: '#105A98', margin: '0 0 0 15px' }}>{props.l1}</span><br />
          <img src={require('../Assets/Rectangle 24.png').default} alt="" /><span style={{ fontSize: '18px', color: '#105A98', margin: '0 0 0 15px' }}>{props.l3}</span><br />
          <img src={require('../Assets/Rectangle 24.png').default} alt="" /><span style={{ fontSize: '18px', color: '#105A98', margin: '0 0 0 15px' }}>{props.l2}</span><br />
        </CardContent >
      </Card>

    </>
  )
}

export default Card1

