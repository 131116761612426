import React from 'react'
import { Card, makeStyles, CardContent } from '@material-ui/core'

const useStyles = makeStyles({
  card: {
    width: '350px',
    height: 'auto',
    background: ' #FFFFFF',
    boxShadow: '10px 10px 35px rgba(0, 0, 0, 0.08)',
    borderRadius: ' 10px',
    position: 'relative',
    marginBottom: '30px'
  },
})

const Card2 = () => {
  const classes = useStyles()
  return (
    <>
      <h1 style={{ color: '#112449', fontSize: '32px', textAlign: 'center', marginTop: '70px' }}>Who We Are</h1>
      <div style={{ width: '100px', height: '2px', backgroundColor: '#112449', margin: '0 auto 50px auto' }}> </div>
      <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
        <Card className={classes.card}>
          <CardContent>
            <img src={require('../Assets/Rectangle 51.png').default} alt="" style={{ width: '340px' }} />
            <div style={{ padding: '0 30px 30px 30px' }}>
              <h2 style={{ color: '#112449', fontSize: '20px', marginTop: '-10px' ,textAlign:'center'}}>NGO brief</h2><br />
              <p style={{ fontSize: '15px', color: '#105A98',textAlign:'justify' }}>Anant Aman Social Welfare Society is a registered NGO under M.P. SOCIETY REGISTRIKARAN ADHINIYAM, 1973 (No.44 of 1973) and certified organisation under 80G and 12A Income Tax (exemption) Act.
                <br /> <br />The NGO has done various social activities in association with other Social groups, Orphanages, Educational Institutions, Corporates, Government authorities, renowned Doctors, and many other social groups.</p>

            </div>
          </CardContent >
        </Card> <br />
        <Card className={classes.card}>
          <CardContent>
            <img src={require('../Assets/Rectangle 54.png').default} alt="" style={{ width: '340px' }} />
            <div style={{ padding: '0 30px 30px 30px' }}>
              <h2 style={{ color: '#112449', fontSize: '20px', marginTop: '-10px' }}>Committee Details</h2><br />
              <p style={{ fontSize: '15px', color: '#105A98',textAlign:'center',listStyle:'none'  }}>
                <li> Mr. Hemant Gupta. 		<br /> Founder and President</li><br />
                <li>Mrs. Deepika Parashar.	<br /> Vice President</li><br />
                <li>Mr. Mradul Tiwari.		<br /> Secretary</li><br />
                <li>Mr. Umang Daga.		<br /> Joint Secretary</li><br />
                <li>Mr. Jairaj Singh Rana.		<br /> Treasurer</li><br />
                <li>Ms. Kalpana Sharma.		<br /> Committee Member</li><br />
                <li>Ms. Anupreet Lubana.		<br /> Committee Member</li>
              </p>

            </div>
          </CardContent >
        </Card> <br />
        <Card className={classes.card}>
          <CardContent>
            <img src={require('../Assets/Rectangle 56.png').default} alt="" style={{ width: '340px' }} />
            <div style={{ padding: '0 30px 30px 30px' }}>
              <h2 style={{ color: '#112449', fontSize: '20px', marginTop: '-10px' }}>DONATE </h2><br />
              <p style={{ fontSize: '15px', color: '#105A98',textAlign:'justify'  }}>
                We are thrilled to have your support. Through your donation we have been able to put more efforts
                and continue working towards upliftmnent and progress of underpreviliged people. You truly make the difference for us,
                and we are extremely grateful!  We shall be thankful for your generous gift to our NGO.<br /><br />
               <strong> Bank Details</strong><br/><br/>
                Name - Anant Aman Social Welfare Society<br/>
                ICICI Bank<br/>
                Branch - Patel Nagar, Indore<br/>
                A/c No. - 657101700241<br/>
                IFSC Code - ICIC0006571</p>

            </div>
          </CardContent >
        </Card> <br />
      </div>
    </>
  )
}

export default Card2

