import React from 'react'
import { NavLink} from 'react-router-dom'
import { AppBar, Toolbar, Button, makeStyles, } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: "20px",
    fontWeight: "600",
    backgroundColor: "#fff",
    color: "#105A98",
    fontSize: "16px",
    border: "2px solid #105A98", 
    [theme.breakpoints.down(500)]: {
      width: "100%",
      margin: '0 0 10px 0'
    },
    "&:hover": {
      backgroundColor: "#105A98",
      color: "#fff",
    },
    icons: {
      position: "absolute",
    },
  },
}));

const Hero = () => {
  const classes = useStyles()
  return (
    <>
      <AppBar style={{ background: '#fff',  boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)",  }} position='static'>
        <Toolbar style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
        <NavLink to="/home"> <img src={require('../Assets/Anant-Aman_Logo-1 1.png').default} alt="" /></NavLink>
          <div style={{ display: 'flex' }}>
            <NavLink to="/contact" style={{ textDecoration: 'none' }} exact><Button className={classes.button}>Contact Us</Button></NavLink>

          </div>
        </Toolbar>
      </AppBar>
      <h1 style={{ color: '#112449', fontSize: '32px', textAlign: 'center', marginTop: '50px' }}>Cancer Pledge</h1>
      <div style={{ width: '100px', height: '2px', backgroundColor: '#112449', margin: '0 auto 50px auto' }}> </div>

      <div>
        <p style={{ width: '80%', textAlign:'center',fontSize: '20px', margin: '0 auto 0 auto',color:'#105A98' }}>
          कैंसर... एक ऐसी बीमारी जिसका नाम सुनते ही लोगों के मन में खौफ पसर जाता है। वह बीमारी जिसने मनोहर पर्रिकर, ऋषि कपूर, इरफान खान, विनोद खन्ना और न जाने ऐसी कितनी हस्तियों समेत लाखों अपनों को हमसे छीन लिया। हमारे देश में कैंसर का दर्द सहते हुए हर मिनट एक शख्स की सांसें थम जाती हैं। मगर सिक्के का दूसरा पहलू भी है।
          <br /> <br />दरअसल, यह बीमारी उतनी भी बेकाबू नहीं, जितनी लगती है। अमेरिका के नेशनल सेंटर फॉर बायोटेक्नोलॉजी इन्फॉरमेशन (NCBI) के अनुसार केवल 20-25% कैंसर के मामले जेनेटिक होते हैं।
          <br /><br />मगर, 75-80% कैंसर के मामले आसपास के वातावरण, खान-पान और रहन-सहन के तौर-तरीकों के चलते होते हैं। यानी हमारा सही लाइफस्टाइल कैंसर से बचने का जबरदस्त मौका देता है। अगर कैंसर हो भी जाए तो सही समय पर पता चलने पर ज्यादातर मामलों में इसका इलाज मुमकिन है।
          <br /> <br />बस जरूरत जागरूक होने की है। युवराज सिंह, मनीषा कोइराला, सोनाली बेंद्रे, ताहिरा कश्यप, अनुराग बासु और राकेश रोशन इसके जाने-पहचाने उदाहरण हैं।
        </p>
      </div>
      <h1 style={{ color: '#112449', fontSize: '32px', textAlign: 'center', marginTop: '100px' ,padding:'10px'}}>Who All Are At The Risk Of Cancer ?</h1>
      <p style={{ color: '#105A98', fontSize: '20px', textAlign: 'center', margin: '10px 0 100px 0' }}>कैंसर किस-किस को हो सकता है</p>

      {/* slider section  */}
      <section >
        <div className="wrapper " >
          
              <div id="carouselExampleDark" class="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators" >
                  <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active"
                    aria-current="true" aria-label="Slide 1"style={{backgroundColor:'#105A98', }} ></button>
                  <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1"
                    aria-label="Slide 2" style={{backgroundColor:'#105A98', }} ></button>  
                </div>
                <div className="carousel-inner"    >
                  <div className="carousel-item active" >

                    <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', textAlign: 'center', margin: '0 0 30px 0' }}>
                      <div style={{marginBottom:'30px'}}>
                        <img src={require('../Assets/Ellipse 7.png').default} alt="" style={{ position: 'absolute', zIndex: '-10', margin: '15px' }} />
                        <img src={require('../Assets/Ellipse 6.png').default} alt="" /><br /><br /><br />
                        <h2 style={{ fontSize: '18px', color: '#105A98', fontWeight: '500' }}>Older Age</h2>
                      </div>

                      <div style={{marginBottom:'30px'}}>
                        <img src={require('../Assets/Ellipse 7.png').default} alt="" style={{ position: 'absolute', zIndex: '-10', margin: '15px' }} />
                        <img src={require('../Assets/Ellipse 9.png').default} alt="" /><br /><br /><br />
                        <h2 style={{ fontSize: '18px', color: '#105A98', fontWeight: '500' }}>A personal or family<br /> history  of cancer</h2>
                      </div>

                      <div style={{marginBottom:'30px'}}>
                        <img src={require('../Assets/Ellipse 7.png').default} alt="" style={{ position: 'absolute', zIndex: '-10', margin: '15px' }} />
                        <img src={require('../Assets/Ellipse 11.png').default} alt="" /><br /><br /><br />
                        <h2 style={{ fontSize: '18px', color: '#105A98', fontWeight: '500' }}>Using Tobacco</h2>
                      </div>

                      <div style={{marginBottom:'30px'}}>
                        <img src={require('../Assets/Ellipse 7.png').default} alt="" style={{ position: 'absolute', zIndex: '-10', margin: '15px' }} />
                        <img src={require('../Assets/Ellipse 13.png').default} alt="" /><br /><br /><br />
                        <h2 style={{ fontSize: '18px', color: '#105A98', fontWeight: '500' }}>Obesity</h2>
                      </div>

                      <div style={{marginBottom:'30px'}}>
                        <img src={require('../Assets/Ellipse 7.png').default} alt="" style={{ position: 'absolute', zIndex: '-10', margin: '15px' }} />
                        <img src={require('../Assets/Ellipse 15.png').default} alt="" /><br /><br /><br />
                        <h2 style={{ fontSize: '18px', color: '#105A98', fontWeight: '500' }}>Alcohol</h2>
                      </div>

                      <div style={{marginBottom:' '}}>
                        <img src={require('../Assets/Ellipse 7.png').default} alt="" style={{ position: 'absolute', zIndex: '-10', margin: '15px' }} />
                        <img src={require('../Assets/Ellipse 17.png').default} alt="" /><br /><br /><br />
                        <h2 style={{ fontSize: '18px', color: '#105A98', fontWeight: '500' }}>Smoking</h2>
                      </div>
                    </div>

                  </div>
                  <div className="carousel-item">

                  <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', textAlign: 'center', margin: '0 0 30px 0' }}>
                      <div style={{marginBottom:'30px'}}>
                        <img src={require('../Assets/Ellipse 7.png').default} alt="" style={{ position: 'absolute', zIndex: '-10', margin: '15px' }} />
                        <img src={require('../Assets/Ellipse 111 (5).png').default} alt="" /><br /><br /><br />
                        <h2 style={{ fontSize: '18px', color: '#105A98', fontWeight: '500' }}>Poor Diet</h2>
                      </div>

                      <div style={{marginBottom:'30px'}}>
                        <img src={require('../Assets/Ellipse 7.png').default} alt="" style={{ position: 'absolute', zIndex: '-10', margin: '15px' }} />
                        <img src={require('../Assets/Ellipse 111 (6).png').default} alt="" /><br /><br /><br />
                        <h2 style={{ fontSize: '18px', color: '#105A98', fontWeight: '500' }}>Specific Chemicals</h2>
                      </div>

                      <div style={{marginBottom:'30px'}}>
                        <img src={require('../Assets/Ellipse 7.png').default} alt="" style={{ position: 'absolute', zIndex: '-10', margin: '15px' }} />
                        <img src={require('../Assets/Ellipse 111 (4).png').default} alt="" /><br /><br /><br />
                        <h2 style={{ fontSize: '18px', color: '#105A98', fontWeight: '500' }}>Some Viral Infections</h2>
                      </div>

                      <div style={{marginBottom:'30px'}}>
                        <img src={require('../Assets/Ellipse 7.png').default} alt="" style={{ position: 'absolute', zIndex: '-10', margin: '15px' }} />
                        <img src={require('../Assets/Ellipse 111 (1).png').default} alt="" /><br /><br /><br />
                        <h2 style={{ fontSize: '18px', color: '#105A98', fontWeight: '500' }}>Pollution</h2>
                      </div>

                      <div style={{marginBottom:'30px'}}>
                        <img src={require('../Assets/Ellipse 7.png').default} alt="" style={{ position: 'absolute', zIndex: '-10', margin: '15px' }} />
                        <img src={require('../Assets/Ellipse 111 (2).png').default} alt="" /><br /><br /><br />
                        <h2 style={{ fontSize: '18px', color: '#105A98', fontWeight: '500' }}>Plastic Usage</h2>
                      </div>

                      <div style={{marginBottom:' '}}>
                        <img src={require('../Assets/Ellipse 7.png').default} alt="" style={{ position: 'absolute', zIndex: '-10', margin: '15px' }} />
                        <img src={require('../Assets/Ellipse 111 (3).png').default} alt="" /><br /><br /><br />
                        <h2 style={{ fontSize: '18px', color: '#105A98', fontWeight: '500' }}>Fast Foods</h2>
                      </div>
                    </div>
                  </div> 
                </div> 
          </div>
        </div>
      </section>




    </>
  )
}

export default Hero
