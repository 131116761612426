import React from 'react'
import Hero from './Hero'
import Main from './Main'
import Main2 from './Main2'
import Footer from './Footer'
const Pledge = () => {
    return (
        <>

            <Hero />
            <Main />
            <Main2 />
            <Footer />
            {/*<Contact/> */}
        </>
    )
}

export default Pledge
