import React from "react";
import { NavLink} from "react-router-dom";

import {
  AppBar,
  Toolbar, 
  makeStyles, 
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  
    images:{width:'60vw',height:'auto',marginTop:'-50px',
    [theme.breakpoints.down(500)]: {
      width:'100%',
       margin:'-50px 0 20px 0'
    },}
}));

const Navbar = () => {
  const classes = useStyles();
  return (
    <>
    {/* "#00E433"  */}
      <AppBar style={{ background:  "#fff"  }} position="static">
        <Toolbar
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
         <NavLink to="/home"> <img
            src={require("../Assets/Anant-Aman_Logo-1 1.png").default}
            alt=""
          /> </NavLink>
        </Toolbar>   
        </AppBar>
      <img src={require("../Assets/img.png").default} alt="" style={{width:'100%',height:'100vh'}} />
        <div style={{textAlign:"center", margin:'-600px auto 0 auto'}}> 
            <div>
              <h1 style={{fontSize:'60px',color:'#fff',fontFamily: 'Aclonica'}}>Happiness Is Here...  </h1><br/><br/><br/>
              <h3 style={{fontSize:'48px',color:'#fff',fontFamily: 'Poor Richard'}}>People Helping People</h3>
            </div> 
              <img src={require("../Assets/Group.png").default}className={classes.images} alt="" style={{}} />  
            <br/> <NavLink to="/home" style={{ textDecoration: "none" }} exact><img src={require("../Assets/bttn.png").default} alt=""  /></NavLink>    
        </div>
     
         
 
    </>
  );
};

export default Navbar;
