import React from 'react'
import { Card, makeStyles, CardContent } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
   card2: {
      width: '360px',
      margin:'0 auto',
      height: '330px',
      background: '#FFFFFF',
      boxShadow: '10px 10px 35px rgba(0, 0, 0, 0.08)',
      borderRadius: '10px',
      [theme.breakpoints.down(500)]: {
         width: "90%", 
         height:'auto',
        textAlign:'center',
        margin:'10px auto'
       },
   },
   img:{
      margin: '-20px 0 0 -25px',
      [theme.breakpoints.down(500)]: {
         width: "100%",
         margin:'auto auto'
       
       },
   }
 }));
 

const Main2 = () => {
   const classes = useStyles()
   return (
      <>

         <h1 style={{ color: '#112449', fontSize: '32px', textAlign: 'center', marginTop: '50px',padding:'8px' }}>Various Types Of Cancer</h1>
         <p style={{ color: '#105A98', fontSize: '20px', textAlign: 'center', margin: '10px 0 50px 0' }}>विभिन्न प्रकार के कैंसर</p>
         <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', }}>
            <Card className={classes.card2}>
               <CardContent  >
                  <img className={classes.img} src={require('../Assets/Rectangle 51 (1).png').default} alt=""  />
                  <h1 style={{ fontSize: '24px', marginTop: '-10px',color:'#105A98',textAlign:'center' }}>Prostate Cancer</h1>
               </CardContent >
            </Card><br />

            <Card className={classes.card2}>
               <CardContent  >
                  <img className={classes.img} src={require('../Assets/Rectangle 365.png').default} alt=""  />
                  <h1 style={{ fontSize: '24px', marginTop: '-10px' ,color:'#105A98',textAlign:'center'}}>Oral Cancer</h1>
               </CardContent >
            </Card><br />

            <Card className={classes.card2}>
               <CardContent  >
                  <img className={classes.img} src={require('../Assets/Rectangle 367.png').default} alt=""  />
                  <h1 style={{ fontSize: '24px', marginTop: '-10px',color:'#105A98',textAlign:'center' }}>Mouth Cancer</h1>
               </CardContent >
            </Card><br />
         </div><br /> 
         <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
            <Card className={classes.card2}>
               <CardContent  >
                  <img className={classes.img} src={require('../Assets/Rectangle 371.png').default} alt=""  />
                  <h1 style={{ fontSize: '24px', marginTop: '-10px',color:'#105A98',textAlign:'center' }}>Skin Cancer</h1>
               </CardContent >
            </Card><br />

            <Card className={classes.card2}>
               <CardContent  >
                  <img className={classes.img} src={require('../Assets/Rectangle 372.png').default} alt=""  />
                  <h1 style={{ fontSize: '24px', marginTop: '-10px',color:'#105A98',textAlign:'center' }}>Lung Cancer</h1>
               </CardContent >
            </Card><br />

            <Card className={classes.card2}>
               <CardContent  >
                  <img className={classes.img} src={require('../Assets/Rectangle 373.png').default} alt=""  />
                  <h1 style={{ fontSize: '24px', marginTop: '-10px',color:'#105A98',textAlign:'center' }}>Stomach Cancer</h1>
               </CardContent >
            </Card><br />
         </div> 
          
      </>
   )
}

export default Main2

