import React from 'react' 
import Navbar from './Navbar'
import Card1 from './Card1'
import Card2 from './Card2'
import Card3 from './Card3'
import Card4 from './Card4'
const Home = () => {

    return (
        <div>

            <Navbar /> 

            <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                <Card1 title="Leadership"
                    l1="Campaigns"
                    l3="Exhibition"
                    l2="CSR PROJECTS" />
                <Card1 title="Philanthropy Program"
                    l1="Campaigns With Us"
                    l3="Volunteer With Us"
                    l2="Internship With Us" />
                <Card1 title="Donate"
                    l1="Blood Donate"
                    l3="Plasma Donate"
                    l2="Platelet Donate" />
            </div>

            <Card2 />
            <Card3 />
            <Card4 />
        </div>
    )
}

export default Home
