import React from 'react'
import { Card, makeStyles, CardContent } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    card: {
        border: 'none',
        boxShadow: 'none',
        width: '100%',
        height: 'auto',

    },
    CardContent: {
        display: 'flex',
        // alignItems: 'center',
    },
    cardImg: {
        width: '80px', height: '80px', borderRadius: '50px', backgroundColor: '#EBF6FF', textAlign: 'center',
        [theme.breakpoints.down(500)]: {

        },
    },
}));

const Card3 = () => {
    const classes = useStyles()
    return (
        <>
            <h1 style={{ color: '#112449', fontSize: '32px', textAlign: 'center', marginTop: '70px' }}>What We Do</h1>
            <div style={{ width: '100px', height: '2px', backgroundColor: '#112449', margin: '0 auto 50px auto' }}> </div>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                <div>
                    <Card className={classes.card}>
                        <CardContent className={classes.CardContent}>
                            <div className={classes.cardImg}  >
                                <img src={require('../Assets/Mask Group.png').default} alt="" style={{ padding: '20px 0 0 0' }} />
                            </div>
                            <div style={{ maxWidth: '300px', margin: '0 0 0 30px' }}>
                                <h1 style={{ fontSize: '20px', color: '#112449' }}>Health Campaings</h1><br/>
                                <p style={{ fontSize: '13px', color: '#105A98',textAlign:'justify'  }}>Free Health campaign were organized at underprivileged areas, villages, shelter homes, chatravas and other needy places.  Our health campaigns consists of Free Eye Check-up, Dental Check-up, General Check-up, Malnutrition screening, Diet counseling , General Health checkups. Few Biochemical tests were also performed including Blood group identification test and Urine Albumin test (for females only). The panchayat, Anganwari workers and ASHA workers also played a crucial role in making various camp a successful one. More than 15000 people took the advantage of this health camps. </p>
                            </div>
                        </CardContent >
                    </Card>
                    <br />
                    <Card className={classes.card}>
                        <CardContent className={classes.CardContent}>
                            <div className={classes.cardImg}>
                                <img src={require('../Assets/Mask Group (1).png').default} alt="" style={{ padding: '20px 0 0 0' }} />
                            </div>
                            <div style={{ maxWidth: '300px', margin: '0 0 0 30px' }}><br/>
                                <h1 style={{ fontSize: '20px', color: '#112449' }}> Nutrition Drive Campaings</h1><br/>
                                <p style={{ fontSize: '13px', color: '#105A98',textAlign:'justify'  }}>On an empty stomach even prayers are tough and in this age of fast life filling stomach can’t serve the purpose. Raising the cause of ‘Providing proper nutrition to our body’, Anant Aman Social Welfare Society Organized a Nutrition Drive among children of an orphanage, where by an interactive session on proper balanced diet and importance of proper nutrition for our body was addressed by Dietitian Miss. Raksha Goyal. Organization also served children with Health drinks spreading awareness about healthy diet and healthier life.</p>
                            </div>
                        </CardContent >
                    </Card>
                </div><br />
                <div >
                    <Card className={classes.card}>
                        <CardContent className={classes.CardContent}>
                            <div className={classes.cardImg}  >
                                <img src={require('../Assets/Mask Group (2).png').default} alt="" style={{ padding: '20px 0 0 0' }} />
                            </div>
                            <div style={{ maxWidth: '300px', margin: '0 0 0 30px' }}>
                                <h1 style={{ fontSize: '20px', color: '#112449' }}>Promoting Yoga </h1><br/>
                                <p style={{ fontSize: '13px', color: '#105A98',textAlign:'justify'  }}>Yoga is a physical, mental, and spiritual practice attributed mostly to India. The Indian Prime Minister Narendra Modi in his UN address suggested the date of 21 June, as it is the longest day of the year in the Northern Hemisphere and shares special significance in many parts of the world.<br /><br />Anant Aman Social Welfare Society had also organized many special yoga session to celebrate international yoga day and to promote healthy life.
                                </p>
                            </div>
                        </CardContent >
                    </Card>
                    <br />
                    <Card className={classes.card}>
                        <CardContent className={classes.CardContent}>
                            <div className={classes.cardImg}  >
                                <img src={require('../Assets/Mask Group (3).png').default} alt="" style={{ padding: '20px 0 0 0' }} />
                            </div>
                            <div style={{ maxWidth: '300px', margin: '0 0 0 30px' }}>
                                <h1 style={{ fontSize: '20px', color: '#112449' }}>Other Awareness Campaigns</h1><br/>
                                <p style={{ fontSize: '13px', color: '#105A98',textAlign:'justify'  }}>
                                    <li> Open Defecation Free program.</li>
                                    <li> Swachh Bharat Abhiyan</li>
                                    <li> International Day of Action for Women's Health</li>
                                    <li> World Diabetes Day under Supervision of Dr. Sandeep JulkaJi</li>
                                    <li> General Etiquettes session at Government Schools</li>
                                   <li>  Blood Donations</li>
                                    <li> Martyrs' Day (Shaheed Diwas) 23rd March</li>
                                    <li> Awareness session towards Organ Donation under supervision of Dr. Sandip Saxena</li>
                                     [DM Nephrology (AIIMS, New Delhi), Vice-President (Indian Society of Organ Transplantation)] 
                                    <li> Matti Ganesh Workshops</li></p>
                            </div>
                        </CardContent >
                    </Card>
                </div>
            </div>
        </>
    )
}

export default Card3

