import React from "react";
import { NavLink } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  makeStyles,
} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  modalOverlay: {
    padding: '10px',
    textAlign: 'center',
    height: 'auto',
    background: '#fff',
    borderRadius: '15px',
    boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down(500)]: {
      width: '95vw',
      height: '100vh,',
    }

  },
  socials: {
    backgroundColor: "#fff",
    position: "absolute",
    right: "0",
    margin: "-40px 0 0 0 ",
    padding: "20px 0 0 0",
    width: "48.26px",
    height: "auto",
    borderRadius: "6px",
    textAlign: "center",
    boxShadow: "-4px 4px 15px rgba(0, 0, 0, 0.08)",
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(500)]: {
      display: 'flex',
      justifyContent: 'space-evenly',
      width: '99%',
      height: 'auto',
      position: 'static',
      flexDirection: 'row',
      marginTop: '5px'
    },
  },
  button: {
    marginLeft: "20px",
    fontWeight: "600",
    backgroundColor: "#fff",
    color: "#105A98",
    fontSize: "16px",
    border: "2px solid #105A98",
    [theme.breakpoints.down(500)]: {
      width: "100%",
      margin: "0 0 10px 0",
    },
    "&:hover": {
      backgroundColor: "#105A98",
      color: "#fff",
    },
    icons: {
      position: "absolute",
    },
  },
}));

const Navbar = () => {

  const classes = useStyles();
  return (
    <>

      <AppBar style={{ background: "#fff", boxShadow: 'none', width: 'auto' }} position="static">
        <Toolbar
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",

          }}
        >
          <NavLink to="/home"><img
            src={require("../Assets/Anant-Aman_Logo-1 1.png").default}
            alt=""
          /></NavLink>

          <div>
            <NavLink to="/contact" style={{ textDecoration: "none" }} exact>
              <Button className={classes.button}>contact us</Button>
            </NavLink>
            <NavLink to="/pledge" style={{ textDecoration: "none" }} exact>
              <Button className={classes.button}>cancer pledge</Button>
            </NavLink>
            <NavLink to="/donate" style={{ textDecoration: "none" }} exact>
              <Button className={classes.button}>blood donation</Button>
            </NavLink>
          </div>
        </Toolbar>
      </AppBar>
      <section >
        <div className="wrapper ">
          <div
            id="carouselExampleDark"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators" style={{ marginBottom: '-30px' }}>
              <button
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
                style={{ backgroundColor: '#105A98', }}
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide-to="1"
                aria-label="Slide 2"
                style={{ backgroundColor: '#105A98', }}
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide-to="2"
                aria-label="Slide 3"
                style={{ backgroundColor: '#105A98', }}
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={require("../Assets/Rectangle 4.png").default}
                  style={{ width: "100%" }}
                />
              </div>
              <div className="carousel-item">
                <img
                  src={require("../Assets/Rectangle 5555 (1).png").default}
                  style={{ width: "100%" }}
                />
              </div>
              <div className="carousel-item">
                <img
                  src={require("../Assets/Rectangle 5555 (2).png").default}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <br />
      <br />
      <div className={classes.socials}  >
        <a href="https://www.facebook.com/AnantAmanSws/" target="_blank">
          <img
            src={require("../Assets/Vector.png").default}
            alt=""
            style={{ margin: "0 0 20px 0 " }}
          />
          <br />
        </a>
        <a href="https://twitter.com/anantamansws" target="_blank">
          <img
            src={require("../Assets/Vector (1).png").default}
            alt=""
            style={{ margin: "0 0 20px 0 " }}
          />
          <br />
        </a>
        <a href="https://www.instagram.com/anantamansws/" target="_blank">
          <img
            src={require("../Assets/Vector (2).png").default}
            alt=""
            style={{ margin: "0 0 20px 0 " }}
          />
          <br />
        </a>
        <a href="mailto: ngo@anantamansws.org" >
          <img
            src={require("../Assets/Vector (3).png").default}
            alt=""
            style={{ margin: "0 0 20px 0 " }}
          />{" "}
        </a>
        <a href="https://www.linkedin.com/company/anantamansws/?originalSubdomain=in" target="_blank">
          <img
            src={require("../Assets/linkedin.png").default}
            alt=""
            style={{ margin: "0 0 20px 0 " }}
          />{" "}
        </a>
      </div>
    </>
  );
};

export default Navbar;
