import React from 'react'
import {
    makeStyles, Button
} from "@material-ui/core";
import Navbar from './Navbar';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        gap: '20px',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        // border: '2px solid red',
        width: '100%',
        padding: '20px',
        // [theme.breakpoints.down(500)]: {
        //     justifyContent: 'center',
        // }
    },
    image: {
        width: '45%',
        [theme.breakpoints.down(500)]: {
            width: '100%',
        }
    }
}));

const BloodDonation = () => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.container}>
                <img src={require("../Assets/blood1.jpeg").default} alt="" className={classes.image} />
                <img src={require("../Assets/blood2.jpeg").default} alt="" className={classes.image} />
            </div>
            <div style={{ padding: '20px', textAlign: 'center' }}>
                <Button variant="contained" >
                    <a href="https://bit.ly/12june1200unit" style={{ color: 'red', textDecoration: 'none' }} target="_blank" >
                        Donate
                    </a>
                </Button>
                <Button variant="contained" style={{ marginLeft: '20px' }}>
                    <a href="https://anantamansws.org/home" style={{ color: 'red', textDecoration: 'none' }}   >
                        Back
                    </a>
                </Button>
            </div>
        </>
    )
}

export default BloodDonation